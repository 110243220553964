import React from 'react';
import './Credentialbox.css';
import { Link } from 'react-router-dom';
import { getUserName } from '@iblai/ibl-web-react-common';

const Credentialbox = ({ credential }) => {
  return (
    <Link
      className="link-block-7 cr w-inline-block"
      to={`/profile/credentials/${credential?.entityId}`}
      key={credential?.entityId}
    >
      <div className="div-block-106">
        <img
          alt=""
          className="image-16"
          loading="lazy"
          src={credential?.credentialDetails?.iconImage ?? 'images/b2.png'}
          //srcSet="images/b2-p-500.png 500w, images/b2.png 512w"
        />
        <div className="text-block-75 credential_name">
          {credential?.credentialDetails?.name}
        </div>
        <div className="text-block-76 cr_date">
          {new Date(credential?.issuedOn)?.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
          })}
        </div>
      </div>
    </Link>
  );
};

export default Credentialbox;
