import { getTenant } from '../hooks/helpers';

export const inIframe = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

export const isExtendedCatalogDisabled = () => {
  return process.env.REACT_APP_IBL_DISABLE_EXTENDED_CATALOG === 'true';
};
