import React, { useState } from 'react';
import EngagementChart from '../../views/EngagementChart';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./TopNotificationBox.css"

const TopNotificationBox = ({ notifications = [] }) => {
  const { t } = useTranslation();

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  return (
    <div className="div-block-33 sp notification-notice-banner-block-container">
      <div className="div-block-13 l-h h w100 ovh">
        <Slider {...settings} >
          {
            notifications.map((banner, index) => (
              <div key={`notification-notice-banner-${index}`}>
                <div className="div-block-14">
                  <div className="skm notification-notice-banner-top-block">
                    <img src="/images/bell-icon.svg" loading="lazy" alt className="" />
                    {banner?.context?.template_data?.message_title}</div>
                </div>
                <div className="notification-notice-banner-content">
                  {banner?.context?.template_data?.message_body ?? ""}
                </div>
              </div>
            ))
          }
        </Slider>
      </div>
    </div>
  );
};

export default TopNotificationBox;