import { useEffect,  useState } from 'react';
import { getNotifications, markNotificationAsRead } from './utils/notifications';

function useUserNotification() {
  const [notifications, setNotifications] = useState([]);
  const [notificationsLoaded, setNotificationsLoaded] = useState(false);


  useEffect(() => {
   
      getNotifications(
        (data) => {
          setNotifications(data);
          setNotificationsLoaded(true);
        },
        1,
        [],
        ''
      );
   
  }, []);

  const handleCloseNotification = (id) => {
    markNotificationAsRead(
      {
        notification_id: id,
        status: 'READ',
      },
      (err, data, response) => {
        console.log(err, data, response, 'Marking as read:', id);
      }
    );
  }

  return {handleCloseNotification, notifications, notificationsLoaded, setNotifications, setNotificationsLoaded };
}

export default useUserNotification;
