import React, { useContext, useEffect, useState } from 'react';
import {
  AppDataContext,
  ProfileBox,
  SkillLeaderBoardBox, useContentSuggestions,
  useIndex,
  useProfileCourses,
} from '@iblai/ibl-web-react-common';
import Focusskillbox from '../../../components/Focusskillbox/Focusskillbox';
import Credentialsbox from '../../../components/Credentialsbox/Credentialsbox';
import Statengagementbox from '../../../components/Statengagementbox/Statengagementbox';
import Monthlyactivitybox from '../../../components/Monthlyactivitybox/Monthlyactivitybox';
import Skillpointbox from '../../../components/Skillpointbox/Skillpointbox';
import Coursesbox from '../../../components/Coursesbox/Coursesbox';
import './IndexViewContent.css';
import useUserNotification from '../../../hooks/useUserNotification';
import dayjs from 'dayjs';
import TopNotificationBox from '../../../components/TopNotificationBox/TopNotificationBox';

const IndexViewContent = () => {
  const [
    userCredentialsData,
    userCredentialsDataLoaded,
    userMonthlyActivityData,
    userMonthlyActivityDataLoaded,
    userPathwaysData,
    userPathwaysDataLoaded,
    userSkillsData,
    userSkillsDataLoaded,
    engagementData,
    engagementDataLoaded,
    skillLeaderBoardData,
    skillLeaderBoardDataLoaded,
    skillPointsGraphData,
    skillPointsGraphDataLoaded,
    ,
  ] = useIndex();
  const { appData, setAppData, tenantMetaData } = useContext(AppDataContext);
  const {content, contentLoaded} = useContentSuggestions('course')
  const {notifications, notificationsLoaded, handleCloseNotification} = useUserNotification()
  const [eventsNotifications, setEventsNotifications] = useState([])
  const [bannerNotifications, setBannerNotifications] = useState([])
  const [assignments, setAssignments] = useState([])
  const [leaderboardDisplayEnabled,setLeaderboardDisplayEnabled] = useState(false);
  /*const [coursesData, courseDataLoaded] = useProfileCourses(
    appData?.userData?.user_display_name
  );*/

  useEffect(() => {
    setAssignments(content?.results?.map((course)=>({
      course
    })) ?? [])
  }, [contentLoaded]);

  useEffect(() => {
    setLeaderboardDisplayEnabled(tenantMetaData?.enable_skills_leaderboard_display !== false)
  }, [tenantMetaData]);

  useEffect(() => {
    if(notificationsLoaded && Array.isArray(notifications)) {
      setEventsNotifications(notifications.filter((_notif)=>_notif?.channel==="Events" && _notif?.status==="UNREAD"));
      setBannerNotifications(notifications.filter((_notif)=>(_notif?.channel==="Banner" || _notif?.channel==="Notice") && _notif?.status==="UNREAD"),)
    }
  }, [notificationsLoaded]);

  const handleCloseAllBannerNotifications = () => {
    setBannerNotifications([]);
    bannerNotifications.forEach((banner)=>{
      handleCloseNotification(banner?.id)
    })
  }

  return (
    <span>
      <div className="div-block-326">
        <div className="div-block-317">
          <div className="panels">
            <div className="panels_cont w_chat">
              <div className="panel-home panel">
                <div className="home_left">
                  <ProfileBox />
                  {
                    eventsNotifications.length > 0 && (
                      <div react-component="focusSkillBox" className="prof_box upcoming-event-block">
                        <div className="div-block-15 less-m-b more-p-x">
                          <div className="text-block sec_title">Upcoming Events</div>
                        </div>
                        <div className="w-layout-vflex notification-event-detail-block">
                          {
                            eventsNotifications.map((event, index)=>(
                              <div key={`event-notification-${index}`} className="w-layout-hflex notification-event-row-box">
                                <h5 className="heading-17">{dayjs(event?.context?.process_on ?? event?.created_at).format("MMM DD")}</h5>
                                <div className="w-layout-hflex flex-block-66">
                                  <div className="text-block-407">{event?.context?.template_data?.message_title || String(event?.context?.template_data?.message_body).slice(0, 40)}...</div>
                                </div>
                              </div>
                            ))
                          }
                        </div>
                      </div>
                    )
                  }
                  {((process.env.REACT_APP_ACTIVE_PROFILE_TABS &&
                    process.env.REACT_APP_ACTIVE_PROFILE_TABS.split(
                      ','
                    ).includes('Skills')) ||
                    !process.env.REACT_APP_ACTIVE_PROFILE_TABS) && (
                    <Focusskillbox
                      userSkillsData={userSkillsData}
                      userSkillsDataLoaded={userSkillsDataLoaded}
                    ></Focusskillbox>
                  )}
                  <Credentialsbox
                    credentialsData={userCredentialsData}
                    userCredentialsDataLoaded={userCredentialsDataLoaded}
                    appData={appData}
                  ></Credentialsbox>
                </div>
                <div className="home_right">
                  {
                    bannerNotifications.length > 0 ?
                      <TopNotificationBox notifications={bannerNotifications} />
                      : <></>
                  }
                  <Statengagementbox
                    engagementData={engagementData}
                    engagementDataLoaded={engagementDataLoaded}
                  ></Statengagementbox>
                  <div className="div-block-33">
                    <div className="div-block-29 w100">
                      <div className="div-block-12 hidden">
                        <div className="text-block-21">Skills</div>
                        <div className="text-block-11">Manage Skills</div>
                      </div>
                      {leaderboardDisplayEnabled && (appData.userMetaData.public_metadata?.enable_skills_leaderboard_display!==false) && (((process.env.REACT_APP_ACTIVE_PROFILE_TABS &&
                          process.env.REACT_APP_ACTIVE_PROFILE_TABS.split(
                            ','
                          ).includes('Skills')) ||
                        !process.env.REACT_APP_ACTIVE_PROFILE_TABS)) && (
                        <SkillLeaderBoardBox
                          percentile={skillLeaderBoardData}
                          percentileLoaded={skillLeaderBoardDataLoaded}
                        />
                      )}
                    </div>
                  </div>
                  <div className="div-block-48 home db">
                    <Monthlyactivitybox
                      userMonthlyActivityData={userMonthlyActivityData}
                      userMonthlyActivityDataLoaded={
                        userMonthlyActivityDataLoaded
                      }
                    ></Monthlyactivitybox>
                    {((process.env.REACT_APP_ACTIVE_PROFILE_TABS &&
                      process.env.REACT_APP_ACTIVE_PROFILE_TABS.split(
                        ','
                      ).includes('Skills')) ||
                      !process.env.REACT_APP_ACTIVE_PROFILE_TABS) && (
                      <Skillpointbox
                        skillPointsGraphData={skillPointsGraphData}
                        skillPointsGraphDataLoaded={skillPointsGraphDataLoaded}
                      ></Skillpointbox>
                    )}
                  </div>
                  {
                    assignments.length > 0 && (
                      <>
                        <div className="text-block-10 mg_b_20">Assignments</div>
                        <Coursesbox
                          userCourseData={assignments}
                          userCourseDataLoaded={contentLoaded}
                        ></Coursesbox>
                        <div className="text-block-13 more"></div>
                      </>
                    )
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*{
          bannerNotifications.length > 0 && (
            <div className="banner-notification-block">
              <div className="w-layout-hflex banner-notification-top-block">
                <div className="w-layout-hflex banner-notification-header"><img src="/images/bell-1.svg" loading="lazy" alt className="image-238" />
                  <h4 className="heading-18">Announcements</h4>
                </div>
                <div onClick={()=>handleCloseAllBannerNotifications()} className="w-layout-hflex banner-notification-top-collapser-box"><img src="/images/x-12.svg" loading="lazy" alt className="image-237" /></div>
              </div>
              <div className="w-layout-vflex banner-notification-content-block">
                {
                  bannerNotifications.map((banner, index)=>(
                    <div className={`w-layout-vflex banner-single-notification-box ${index===0 ? "first" : ""} ${index === (bannerNotifications.length-1) ? "last" : ""}`}>
                      <h5 className="heading-19">{banner?.context?.template_data?.message_title}</h5>
                      <div className="text-block-408">{banner?.context?.template_data?.message_body ?? ""}</div>
                      <img onClick={()=>{
                        handleCloseNotification(banner?.id)
                        setBannerNotifications((old)=>(old.filter((_notif)=>_notif?.id!==banner?.id)))
                      }} src="/images/x.svg" loading="lazy" alt className="image-239" />
                    </div>
                  ))
                }
              </div>
            </div>
          )
        }*/}
      </div>
    </span>
  );
};
export default IndexViewContent;
