import './ResumeUpload.css';
import {
  api, AppDataContext,
  getTenant,
  getUserId,
  getUserName,
  IBLSpinLoader,
} from '@iblai/ibl-web-react-common';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

const ResumeUpload = ({
  setResumeUploaded,
  setErrorMessage,
  setSelectedFile,
  selectedFile,
  errorMessage,
  resumeUploaded,
}) => {
  const { t } = useTranslation();
  const { appData:{userMetaData}, setAppData } = useContext(AppDataContext);
  const uploadPhotoInputElement = useRef(null);
  const MAX_FILE_SIZE = 1024 * 1024;
  const fileInputRef = useRef(null);
  const [isUploading, setIsUploading] = useState(false);
  const [profilePic, setProfilePic] = useState("")

  useEffect(() => {
    if(userMetaData?.profile_image?.has_image){
      setProfilePic(userMetaData?.profile_image?.image_url_full)
    }
  }, [userMetaData]);

  // IMAGE UPLOAD

  const userCanStoreProfilePic = () => {
    const actualYearOfBirth = userMetaData?.year_of_birth;
    if (!actualYearOfBirth) {
      return false;
    }
    try {
      return new Date().getFullYear() - actualYearOfBirth >= 13;
      //ACCEPTABLE AGE LIMIT FOR EDX TO STORE PICTURE PIC
    } catch {
      return false;
    }
  };

  function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
      const cookies = document.cookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        // Does this cookie string begin with the name we want?
        if (cookie.substring(0, name.length + 1) === name + '=') {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }
    return cookieValue;
  }

  // TODO : Call these endpoints from the ibl web core front end instead of making a direct call
  const handleStoreProfilePic = (formData) => {
    const username = getUserName();
    const learnURL = process.env.REACT_APP_IBL_LMS_URL;
    fetch(`${learnURL}/api/user/v1/accounts/${username}/image`, {
      method: 'POST',
      headers: {
        'X-CSRFToken': getCookie('csrftoken'),
      },
      body: formData,
      redirect: 'follow',
      credentials: 'include', // This line is added to include credentials like cookies
    })
      .then((response) => response.text())
      .then((result) => {
        fetch(`${learnURL}/api/user/v1/accounts/${username}`, {
          method: 'GET',
          credentials: 'include', // This line is added to include credentials like cookies
        })
          .then((response) => response.json())
          .then((fetchedUserData) => {
            const updatedMetaData = {
              ...userMetaData,
              public_metadata: {
                ...userMetaData?.public_metadata,
                profile_image: fetchedUserData?.profile_image,
              },
              profile_image: fetchedUserData?.profile_image,
            };
            setAppData(old=>({
              ...old,
              userMetaData:{ ...updatedMetaData }
            }));
            //setUserProfilePicUpdated(true);
          });
      })
      .catch((error) => console.error(error));
  };

  const handleImgUploadToAPI = (formdata) => {
    //setIsSubmitting(true);
    const userCanStore = userCanStoreProfilePic();
    if (userCanStore) {
      handleStoreProfilePic(formdata);
    } else {
      handleUpdateUserDateOfBirth()
        .then((response) => {
          handleStoreProfilePic(formdata);
        })
        .catch((err) => {
          //
        });
    }
  };

  const handleUpdateUserDateOfBirth = async () => {
    const username = getUserName()
    const learnURL = process.env.REACT_APP_IBL_LMS_URL;
    const apiUrl = `${learnURL}/api/user/v1/accounts/${username}`;
    const DEFAULT_YEAR_OF_BIRTH = '1996';

    const payload = {
      year_of_birth: DEFAULT_YEAR_OF_BIRTH,
    };

    return fetch(apiUrl, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/merge-patch+json',
      },
      body: JSON.stringify(payload),
      credentials: 'include',
    }).then((response) => response.json());
  };

  const handleImgUploadChange = async (e) => {
    if (!e) {
      return;
    }
    const file = e?.target?.files[0];
    if (file.size >= MAX_FILE_SIZE) {
      alert(t('Profile pic must not exceed 1Mb'));
      return;
    }
    const blobImg = URL.createObjectURL(file);
    setProfilePic(blobImg);

    /*const formdata = new FormData();
    formdata.append("file", file)*/
    uploadPhotoInputElement.current.value = '';
    //const base64 = await convertToBinary(e);

    const formdata = new FormData();
    // Assuming `fileInput` is a reference to an <input type="file"> in your HTML
    formdata.append('file', file);
    handleImgUploadToAPI(formdata)
  };

  const handleImgUploadClick = () => {
    uploadPhotoInputElement.current.click();
  };

  //CV UPLOAD
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.size > 5242880) {
      // 5MB in bytes
      window.alert('File size should be less than 25MB');
    } else if (file && !['application/pdf'].includes(file.type)) {
      window.alert('Invalid file type. Please upload a PDF.');
    } else {
      handleSubmit(file);
    }
  };

  const handleFileDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    handleFileChange({ target: { files: [file] } });
  };

  const handleSubmit = (file) => {
    if (file) {
      const formData = new FormData();
      formData.append('resume', file);
      formData.append('user', getUserName());
      const DEFAULT_TENANT = process.env.REACT_APP_IBL_PROFILE_RESUME_FEATURE_TENANT_TO_USE_WITH_ENDPOINTS ?? getTenant()
      formData.append('platform', DEFAULT_TENANT);

      setResumeUploaded(false);
      setErrorMessage('');
      setIsUploading(true);

      api.ibldmplatform.uploadResume(
        { org: DEFAULT_TENANT, userID: getUserName() },
        formData,
        (data) => {
          setResumeUploaded(true);
          setSelectedFile(file);
          setErrorMessage('');
          setIsUploading(false);
        },
        (error) => {
          setResumeUploaded(true);
          setIsUploading(false);

          if (fileInputRef.current) {
            fileInputRef.current.value = '';
          }
          if (error?.status === 400) {
            setErrorMessage('User resume with this user already exists');
            return;
          }
          window.alert('Error uploading resume. Please try again later.');
          console.error(error);
        }
      );
    }
  };

  //SOCIAL MEDIA
  const handleInputChange = (e) => {
    const otherSocialLinks =
      userMetaData?.social_links?.filter(
        (el) => el?.platform !== e?.target?.name
      ) || [];
    const actualSocialLink = {
      platform: e?.target?.name,
      social_link: e?.target?.value,
    };
    setAppData(old=>({
      ...old,
      userMetaData:{
        ...userMetaData,
        social_links: [...otherSocialLinks, actualSocialLink],
      }
    }));
  };

  const getSocialUrl = (platform) => {
    return (
      userMetaData?.social_links?.find((el) => el?.platform === platform)
        ?.social_link || ''
    );
  };

  return (
    <>
      <div className="div-block-78 res more-gap">
        <div className="div-block-84 level_rate start-optional-tabs-box first">
          <div className="text-block-62 res no-top-space">Profile Picture (Optional)</div>
          <div className="text-block-147">Add a photo to personalize your profile</div>
          <div onClick={() => handleImgUploadClick()} style={{backgroundImage:profilePic ? `url(${profilePic})` : "unset"}}
               className={`w-layout-hflex start-optional-upload-box ${profilePic ? 'active' : ''}`}>
            <div className={`w-layout-hflex start-optional-upload-btn ${profilePic ? '' : 'hidden'}`}><img
              src="/images/camera-1.svg"
              loading="lazy" alt
              className="image-235" /></div>
            <img src="/images/camera-1.svg" loading="lazy" alt className={`image-236 ${profilePic ? 'hidden' : ''}`} />
            <input
              ref={uploadPhotoInputElement}
              onChange={handleImgUploadChange}
              type="file"
              className="input-mentor-image-upload"
              accept="image/*"
            />
          </div>
        </div>
        <div className="div-block-84 level_rate start-optional-tabs-box no-m-top">
          <div className="text-block-62 res no-top-space">Social Networks (Optional)</div>
          <div className="text-block-147">Add your social network profiles<br /></div>
          <div className="start-optional-socials-form-block w-form">
            <form id="email-form-3" name="email-form-3" data-name="Email Form 3" method="get"
                  className="start-optional-socials-form" data-wf-page-id="6596e21ac435cac3c66baaef"
                  data-wf-element-id="db1f42e3-b647-4ddb-1f0a-260e70b885c7"><label htmlFor="name-2"
                                                                                   className="field-label-10">Linkedin
              Profile Username</label><input className="text-field-23 w-input" maxLength={256} name="linkedin"
                                        value={getSocialUrl('linkedin')}
                                        onChange={handleInputChange}
                                        data-name="Name 2" placeholder="Linkedin username" type="text"
                                        id="name-2" /><label htmlFor="name-2" className="field-label-10">X Profile
              Username</label><input className="text-field-23 w-input" maxLength={256} name="twitter"
                                value={getSocialUrl('twitter')}
                                onChange={handleInputChange} data-name="Name 2"
                                placeholder="X username" type="text" id="name-2" /><label
              htmlFor="name-2" className="field-label-10">Facebook Profile Username</label><input
              className="text-field-23 w-input" maxLength={256} name="facebook"
              value={getSocialUrl('facebook')}
              onChange={handleInputChange} data-name="Name 2"
              placeholder="Facebook username" type="text" id="name-2" /></form>
            <div className="w-form-done">
              <div>Thank you! Your submission has been received!</div>
            </div>
            <div className="w-form-fail">
              <div>Oops! Something went wrong while submitting the form.</div>
            </div>
          </div>
        </div>
        <div className="div-block-84 level_rate start-optional-tabs-box no-m-top">
          <div className="text-block-62 res no-top-space">Upload Your Resume (Optional)</div>
          <div className="text-block-147">
            Our AI Engine will detect and add your skills to your learning
            profile
          </div>

          {isUploading ? (
            <div style={{ marginTop: '10px' }}>
              <IBLSpinLoader size={40} />
            </div>
          ) : (
            <div
              className="div-block-233 less-m-top"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                if (fileInputRef.current) {
                  fileInputRef.current.click();
                }
              }}
              onDrop={handleFileDrop}
              onDragOver={(e) => e.preventDefault()}
            >
              <div className="div-block-234 div-block-234-custom">
                <div className="div-block-235 div-block-235-custom">
                  <img
                    src="/images/upload.png"
                    loading="lazy"
                    alt=""
                    className="image-93"
                  />
                </div>
                <div>
                  <div className="text-block-148 text-block-148-custom">
                    Drag and drop your resume here
                  </div>
                  <div
                    className="text-block-149"
                    style={{ textAlign: 'center' }}
                  >
                    (PDF up to 25MB)
                  </div>

                  <>
                    <input
                      type="file"
                      accept=".pdf"
                      onChange={handleFileChange}
                      ref={fileInputRef}
                      style={{ display: 'none' }}
                      id="fileInput"
                    />
                  </>
                </div>
              </div>

              {selectedFile && (
                <div
                  className="file-name"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: '10px',
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="lucide lucide-file-text"
                    style={{ marginRight: '8px' }}
                  >
                    <path d="M15 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7Z" />
                    <path d="M14 2v4a2 2 0 0 0 2 2h4" />
                    <path d="M10 9H8" />
                    <path d="M16 13H8" />
                    <path d="M16 17H8" />
                  </svg>
                  <span>{selectedFile.name}</span>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ResumeUpload;
