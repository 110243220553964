import React, { useState } from 'react';
import './Contentfilterbox.css';
import { getTenant, toPascalCaseWithSpaces } from '../../hooks/helpers';
import { useTranslation } from 'react-i18next';
import { isExtendedCatalogDisabled } from '../../utils/helpers';

const Contentfilterbox = ({
  discoverItems,
  selectedTerms,
  setSelectedTerms,
  facetHandler,
  setDiscoverLoaded,
  isLoading,
}) => {
  const { t } = useTranslation();

  const convertFacetData = (facetData) => {
    const enableDurationFacet =
      process.env.REACT_APP_IBL_ENABLE_DURATION_FACET !== 'false';
    let facets = Object.entries(facetData || {}).reduce(
      (acc, [facetName, facetInfo]) => {
        if (facetName === 'duration' && !enableDurationFacet) return acc;
        let terms = [];
        if (facetName === 'providers') {
          terms = Object.entries(facetInfo.terms).map(([term, obj]) => ({
            term: term,
            count: obj.count,
            name: obj.name,
          }));
          if (isExtendedCatalogDisabled()) {
            terms = terms.filter((term) => term.term === getTenant());
          }
        } else if (facetName === 'content') {
          terms = Object.entries(facetInfo)
            .filter(([term, count]) => count > 0)
            .map(([term, count]) => ({
              term,
              count,
              /*count: obj.count,
            name: obj.name,*/
            }));
        } else {
          terms = Object.entries(facetInfo.terms).map(([term, count]) => ({
            term: term,
            count,
          }));
        }
        const facetObject = {
          name: facetName,
          total: facetInfo.total,
          terms,
        };

        if (facetObject.terms.length > 0) {
          acc.push(facetObject);
        }
        return acc;
      },
      []
    );
    let contentIndex = facets.findIndex((item) => item.name === 'content');

    // If found, move it to the top
    if (contentIndex !== -1) {
      let contentItem = facets.splice(contentIndex, 1)[0];
      facets.unshift(contentItem);
    }
    return facets;
  };

  const [facetVisibility, setFacetVisibility] = useState({});
  const [filterText, setFilterText] = useState({});

  const handleFacetClick = (facetName) => {
    setFacetVisibility((prevVisibility) => ({
      ...prevVisibility,
      [facetName]: !prevVisibility[facetName],
    }));
  };

  const handleTermClick = (facetName, term, name) => {
    setDiscoverLoaded(false);
    const processedTerm = `${facetName}:${term}`;

    setSelectedTerms((prevSelectedTerms) => {
      const isSelected = prevSelectedTerms.includes(processedTerm);
      const newSelectedTerms = isSelected
        ? prevSelectedTerms.filter(
            (selectedTerm) => selectedTerm !== processedTerm
          )
        : [...prevSelectedTerms, processedTerm];
      facetHandler(newSelectedTerms);
      return newSelectedTerms;
    });
  };

  const handleInputChange = (facetName, e) => {
    const value = e.target.value;
    setFilterText((prevFilterText) => ({
      ...prevFilterText,
      [facetName]: value,
    }));
  };

  const isTermSelected = (facetName, term) => {
    // if (facetName === 'provider') {
    //   facetName = 'tenant';
    // }
    const processedTerm = `${facetName}:${term}`;
    return selectedTerms.includes(processedTerm);
  };

  return (
    <div
      data-testid="content-filter-box-container"
      className={isLoading ? 'greyed-out' : ''}
    >
      <div className="f_box" style={{ paddingTop: '0' }}>
        <div
          no-mapping=""
          className="text-block sec_title ex_co"
          style={{ marginBottom: '0' }}
        >
          {t('Explore Content')}
        </div>
      </div>

      <div className="f_box">
        <div no-mapping="" className="text-block sec_title fb">
          {t('Filter By')}
        </div>
      </div>
      {convertFacetData(discoverItems).map((facet, index) => {
        if (facet.name === 'providers' && isExtendedCatalogDisabled())
          return <></>;
        return (
          <div className="f_box" key={index}>
            <div className="f_row" onClick={() => handleFacetClick(facet.name)}>
              <div prop-data-value-label="Type" prop-data-names="label">
                {facet.name.charAt(0).toUpperCase() + facet.name.slice(1)}
              </div>
              <img
                src="https://assets-global.website-files.com/6596e21ac435cac3c66baad8/6596e21ac435cac3c66bac9f_plus.png"
                loading="lazy"
                alt="Expand"
                className="img_v"
                style={{
                  display: facetVisibility[facet.name]
                    ? 'none'
                    : 'inline-block',
                }}
              />
              <img
                src="https://assets-global.website-files.com/6596e21ac435cac3c66baad8/6596e21ac435cac3c66bacc9_minus-sign.png"
                loading="lazy"
                alt="Collapse"
                className="img_h"
                style={{
                  display: facetVisibility[facet.name]
                    ? 'inline-block'
                    : 'none',
                }}
              />
            </div>
            <div
              className="form-block filter w-form"
              style={{
                display: facetVisibility[facet.name] ? 'block' : 'none',
              }}
            >
              <form id="email-form" name="email-form" className="form filter">
                <div className="div-block-9">
                  <input
                    className="text-field w-input"
                    maxLength={256}
                    name="filter-facet-terms"
                    placeholder={t('Filter')}
                    type="text"
                    id="filter-facet-terms"
                    value={filterText[facet.name] || ''}
                    onChange={(e) => handleInputChange(facet.name, e)}
                  />
                  <div className="div-block">
                    <img
                      src="https://assets-global.website-files.com/6596e21ac435cac3c66baad8/6596e21ac435cac3c66baba3_loupe.png"
                      loading="lazy"
                      alt="Search Icon"
                      className="image-2"
                    />
                  </div>
                </div>
                {facet.terms
                  .filter((term) =>
                    term.term
                      .toLowerCase()
                      .includes((filterText[facet.name] || '').toLowerCase())
                  )
                  .map((term, termIndex) => {
                    const filterName =
                      term?.name ?? toPascalCaseWithSpaces(term.term);
                    return (
                      <label
                        key={termIndex}
                        className="w-checkbox checkbox-field"
                      >
                        <input
                          type="checkbox"
                          id={`checkbox-${index}-${termIndex}`}
                          name={`checkbox-${index}-${termIndex}`}
                          className="w-checkbox-input checkbox"
                          checked={isTermSelected(facet.name, term.term)}
                          onChange={() =>
                            handleTermClick(facet.name, term.term, term.name)
                          }
                        />
                        <span
                          className="checkbox-label w-form-label correct-text-wrap"
                          htmlFor={`checkbox-${index}-${termIndex}`}
                        >
                          {`${facet.name === 'providers' ? filterName.toUpperCase() : filterName}`}{' '}
                          {term.count ? `(${term?.count})` : '(0)'}
                        </span>
                      </label>
                    );
                  })}
              </form>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Contentfilterbox;
