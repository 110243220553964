import { getTenant } from '@iblai/ibl-web-react-common';

export const getTenantMetaData = (callback) => {
  const url = process.env.REACT_APP_IBL_DM_URL + `/api/core/orgs/${getTenant()}/metadata/`
  fetch(url, {
    method: 'GET',
    //body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Token ' + localStorage.getItem('axd_token'),
    },
  }).then((resp) => {
    resp.json().then(callback).catch(callback);
  }).catch(callback);
}