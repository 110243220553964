import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { api, getUserName } from '@iblai/ibl-web-react-common';
import { getQueryParamValue, getTenant, queryParamsToDict } from './helpers';

import { filterFacetsKeys, getFacetItemsAPI } from './helpers';
import { isExtendedCatalogDisabled } from '../utils/helpers';

const FACET_SEPARATOR = '~|*';

function useDiscover() {
  const [discoverItems, setDiscoverItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [discoverLoaded, setDiscoverLoaded] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const location = useLocation();
  const navigate = useNavigate();
  const [contentFilterBoxItems, setContentFilterBoxItems] = useState({});
  const rendered = useRef(false);

  const FACETS_TO_HIDE =
    process.env.REACT_APP_IBL_DISCOVER_FACETS_FILTERS_TO_HIDE;

  useEffect(() => {
    getFacetItemsAPI((facets) => {
      setContentFilterBoxItems(
        FACETS_TO_HIDE ? filterFacetsKeys(facets, FACETS_TO_HIDE) : facets
      );
    });
  }, [location.search]);

  const [selectedTerms, setSelectedTerms] = useState(['content:courses']);

  const queryParam = new URLSearchParams(location.search);
  const search = queryParam.get('q');

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (!queryParam.get('content')) {
      searchParams.set('content', 'courses');
    }
    if (isExtendedCatalogDisabled() && !queryParam.get('providers')) {
      searchParams.set('providers', getTenant());
    }
    navigate(`${location.pathname}?${searchParams.toString()}`, {
      replace: true,
    });
  }, []);

  useEffect(() => {
    const termsDict = queryParamsToDict();
    const _selectedTerms = [];
    Object.entries(termsDict).forEach(([key, value]) => {
      value
        .split(',')
        .forEach((val) =>
          _selectedTerms.push(`${key}:${val.replace(FACET_SEPARATOR, ',')}`)
        );
    });
    setSelectedTerms(_selectedTerms);
    if (termsDict['content']) {
      getCatalog(_selectedTerms);
    }
  }, [location.search]);

  useEffect(() => {}, [contentFilterBoxItems]);

  const extractCourses = (results) => {
    return results.map((result) => ({ ...result.data, type: result.type }));
  };

  const discoverCallback = (data) => {
    setDiscoverItems(data);
    setDiscoverLoaded(true);
  };

  const updateFacets = (facets) => {
    Object.entries(facets).forEach(([key, value]) => {
      Object.entries(contentFilterBoxItems[key].terms).forEach(
        ([key1, value1]) => {
          if (Object.keys(value.terms).includes(key1)) {
            contentFilterBoxItems[key].terms[key1].count =
              value.terms[key1].count;
          } else {
            contentFilterBoxItems[key].terms[key1].count = 0;
          }
        }
      );
    });
    setContentFilterBoxItems(contentFilterBoxItems);
  };

  const getCatalog = (newSelectedTerms) => {
    const requestData = getFacetRequestObject(newSelectedTerms);
    requestData['update_facet'] = true;
    setCurrentPage(1);
    requestData['limit'] = 12;
    const queryParamsDict = queryParamsToDict();
    console.log('########### queryParams dict getCatalog', queryParamsDict);
    if (queryParamsDict['content']) {
      requestData['content'] = queryParamsDict['content'];
    }
    const username = getUserName();
    setDiscoverLoaded(false);
    const searchQueryValue = getQueryParamValue();
    if (searchQueryValue) {
      requestData['query'] = searchQueryValue;
    }
    if (isExtendedCatalogDisabled()) {
      requestData['tenant'] = getTenant();
    }
    if (username) {
      requestData['username'] = username;
      api.ibldmsearch.searchPersonalizedCatalog(requestData, (_data) => {
        const calculatedPages = Math.ceil(_data.count / 12);
        setTotalPages(calculatedPages);
        discoverCallback(extractCourses(_data.results));
        if (Object.keys(contentFilterBoxItems).length) {
          updateFacets(_data.facets);
          // rendered.current = true;
        }
      });
    } else {
      api.ibldmsearch.searchCatalog({ ...requestData }, (_data) => {
        const calculatedPages = Math.ceil(_data.count / 12);
        setTotalPages(calculatedPages);
        discoverCallback(extractCourses(_data.results));
        if (Object.keys(contentFilterBoxItems).length) {
          updateFacets(_data.facets);
          // rendered.current = true;
        }
      });
    }
  };

  const convertTermToDict = (terms) => {
    const obj = {};
    terms.forEach((term) => {
      const [key, value] = term.split(':');
      if (key in obj) {
        if (typeof obj[key] === 'string') {
          obj[key] = [obj[key], value.replace(',', FACET_SEPARATOR)];
        } else if (Array.isArray(obj[key])) {
          obj[key].push(value.replace(',', FACET_SEPARATOR));
        }
      } else {
        obj[key] = value.replace(',', FACET_SEPARATOR);
      }
    });
    return obj;
  };

  const updateQueryParams = (newParams) => {
    const searchParams = new URLSearchParams('');

    for (const key in newParams) {
      if (newParams[key] === null) {
        searchParams.delete(key);
      } else {
        searchParams.set(key, newParams[key]);
      }
    }
    navigate(`${location.pathname}?${searchParams.toString()}`, {
      replace: true,
    });
  };

  const getFacetRequestObject = (_selectedTerms) => {
    const requestData = {};
    (_selectedTerms || []).forEach((_term) => {
      let [term, value] = _term.split(':');
      if (term === 'providers') {
        term = 'tenant';
      }
      if (term === 'format') {
        term = 'courseformat';
      }
      if (term === 'course duration') {
        term = 'duration';
      }
      if (requestData.hasOwnProperty(term)) {
        if (Array.isArray(requestData[term])) {
          requestData[term].push(value);
        } else {
          requestData[term] = [requestData[term], value];
        }
      } else {
        requestData[term] = value;
      }
    });
    return requestData;
  };

  const paginate = ({ selected }) => {
    const requestData = getFacetRequestObject(selectedTerms);
    const newPage = selected + 1;
    setCurrentPage(newPage);

    requestData['limit'] = 12;
    if (selected > 0) {
      requestData['offset'] = selected * 12;
    }
    const searchQueryValue = getQueryParamValue();
    if (searchQueryValue) {
      requestData['query'] = searchQueryValue;
    }

    const username = getUserName();
    setDiscoverLoaded(false);

    if (username) {
      requestData['username'] = username;
      api.ibldmsearch.searchPersonalizedCatalog(requestData, (_data) => {
        const calculatedPages =
          _data.total_pages || Math.ceil(_data.count / 12);
        setTotalPages(calculatedPages);
        discoverCallback(extractCourses(_data.results));
      });
    } else {
      api.ibldmsearch.searchCatalog(requestData, (_data) => {
        const calculatedPages =
          _data.total_pages || Math.ceil(_data.count / 12);
        setTotalPages(calculatedPages);
        discoverCallback(extractCourses(_data.results));
      });
    }
  };

  return {
    discoverItems,
    discoverLoaded,
    setDiscoverItems,
    setDiscoverLoaded,
    selectedTerms,
    setSelectedTerms,
    totalPages,
    setTotalPages,
    convertTermToDict,
    updateQueryParams,
    getFacetRequestObject,
    currentPage,
    setCurrentPage,
    paginate,
    contentFilterBoxItems,
    setContentFilterBoxItems,
  };
}

export default useDiscover;
