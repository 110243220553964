import { useEffect, useState } from 'react';

import './App.css';
import '@iblai/ibl-web-react-common/index.css';
import route from './routes';
import {
  AppDataContext,
  GLOBAL_COLORS,
  isJSON,
  api,
  VisibilityProvider, getUserAdminCapabilities,
} from '@iblai/ibl-web-react-common';
import { RouterProvider } from 'react-router-dom';
import { inIframe } from './utils/helpers';
import { createGlobalStyle } from 'styled-components';
import { useIblTranslation } from '@iblai/ibl-web-react-common';
import { Toaster } from 'react-hot-toast';

import '@shoelace-style/shoelace/dist/themes/light.css';
import { setBasePath } from '@shoelace-style/shoelace/dist/utilities/base-path';

setBasePath('https://cdn.jsdelivr.net/npm/@shoelace-style/shoelace@2.18.0/cdn/');

const initiateAppContextData = () => {
  return {
    ...localStorage,
    userData: isJSON(localStorage['userData'])
      ? JSON.parse(localStorage['userData'])
      : null,
    ibl_user_public_metadata: isJSON(
      localStorage.getItem('ibl_user_public_metadata')
    )
      ? JSON.parse(localStorage.getItem('ibl_user_public_metadata'))
      : null,
    userMetaData: {},
    userMetaDataLoaded: false,
    userInContext: '',
    inIframe: inIframe(),
  };
};

function App() {
  const [appData, setAppData] = useState(initiateAppContextData);
  const { translate } = useIblTranslation();
  const [tenantMetaData, setTenantMetaData] = useState({});
  const [userAdminCapabilities, setUserAdminCapabilities] = useState([]);

  const checkUserAdminCapabilities = async () => {
    const adminCheckInLocalstorage = localStorage.getItem('userAdminCheck')
    let userAdminCheck = isJSON(adminCheckInLocalstorage) ? JSON.parse(adminCheckInLocalstorage) : false
    if(!userAdminCheck){
      userAdminCheck = await getUserAdminCapabilities()
    }
    //REDUCING REDUNDANT ENDPOINT CALL
    localStorage.setItem('userAdminCheck', JSON.stringify(userAdminCheck));
    setUserAdminCapabilities(userAdminCheck)
  }

  useEffect(() => {
    api.initiateAxdClientsWithToken(appData.axd_token);
    if (appData.userMetaData?.language) {
      translate(appData.userMetaData.language);
    }
    checkUserAdminCapabilities()
  }, []);

  return (
    <>
      <GlobalStyles />
      <AppDataContext.Provider value={{ appData: { ...appData }, setAppData, setTenantMetaData, tenantMetaData, userAdminCapabilities }}>
        <VisibilityProvider>
          <>
            <RouterProvider router={route(appData, setAppData)} />
            <Toaster position="top-right" reverseOrder={false} />
          </>
        </VisibilityProvider>
      </AppDataContext.Provider>
    </>
  );
}

const GlobalStyles = createGlobalStyle`
  html {
    --grey: ${GLOBAL_COLORS.grey};
    --font: ${GLOBAL_COLORS.font};
    --accent: ${GLOBAL_COLORS.primary};
    --accenthover: ${GLOBAL_COLORS.secondary};
    --accent-2: ${GLOBAL_COLORS.tertiary};
    --gainsboro: ${GLOBAL_COLORS.grey_secondary};
    --white-smoke: ${GLOBAL_COLORS.white_smoke};
    --white: white;
    --black: black;
    --3: white;
  }
  body {
      background-color: ${inIframe() ? '#282c34' : '#fff'};
  }
  ${inIframe() ? `
    :root{
      --font: #FFFFFF!important;
    }
    .skills-c * {
      color: #FFFFFF!important;
    }
    .prof_menu.first.active{
      color: #FFFFFF!important;
    }
    body:has(.about-body-2){
      background-color: transparent!important;
    }
  ` : ""}
`;
export default App;
